html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
  background-color: white;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #aeb8c5;
  border-radius: 4.5px;
  position: relative;
  right: 4px;
}

input {
  outline: none;
}
